export const environment = {
    //Production environment
    production: true,
    //     baseUrl: "http://localhost:9090/api/v1",

    baseUrl: "https://horecacode.it:8444/api/v1",

    // production: false,
    // baseUrl: "https://horecacode-57b2a6aa10e8.herokuapp.com/api/v1"
};
